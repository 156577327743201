import React, { Component} from 'react';

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recentError: ""
    };
    this.submit = this.submit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.render = this.render.bind(this);
  }

  submit() {
    // You cannot modify the state object inside of a class directly.
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <div>
        <form action="form-handler.php" method="post" class="radius10 pt-70 pb-55 px-30">
          <h2 class="mb-40 small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">Join the Early Access List</h2>
        </form>
      </div>
    );
  }
}


// export default withStyles(styles)(Signup);
export default SignupForm;


/*
<h2 class="mb-40 small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">Join the Early Access List</h2>
<div data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
  <input type="text" name="name" placeholder="Your name" class="mb-20 mr-10 w-400 input md border-gray focus-action-1 color-heading placeholder-heading text-center text-md-left" required /><br/>
  <input type="email" name="email" placeholder="Your email" class="mb-20 mr-10 w-400 input md border-gray focus-action-1 color-heading placeholder-heading text-center text-md-left" required /><br/>
  <div class="dropdown">
    <button class="btn md mb-20 mr-10 w-400 text-adaptive btn-secondary dropdown-toggle text-md-left active" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" required>
      I am a...
    </button>
    <div class="dropdown-menu md" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item md" href="#">Startup founder</a>
      <a class="dropdown-item md" href="#">Startup advisor</a>
      <a class="dropdown-item md" href="#">Startup investor</a>
      <a class="dropdown-item md" href="#">Other</a>
    </div>
  </div>
  <div class="dropdown">
    <!-- <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> -->
    <button class="btn md mb-20 mr-10 w-400 text-adaptive btn-secondary dropdown-toggle text-md-left active" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" width="100%" required>
      My interest in the product...
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" href="#">Connecting with founders/advisors</a>
      <a class="dropdown-item" href="#">Streamlining advisory engagements</a>
      <a class="dropdown-item" href="#">Simplifying compensation & paperwork</a>
      <a class="dropdown-item" href="#">Other</a>
    </div>
  </div>
  <input type="text" name="comments" placeholder="Questions? Comments?" class="mb-20 mr-10 w-400 input md border-gray focus-action-1 color-heading placeholder-heading text-center text-md-left" /><br/>
  <button class="btn lg action-1" type="button">Sign Up</button>
</div>
*/


/*
      <Select
        value = {this.state.startupStage}
        onChange = {this.handleChange}
        name = "startupStage"
        margin = "normal"
      >
        <MenuItem value={0}>Pre-seed</MenuItem>
        <MenuItem value={1}>Seed</MenuItem>
        <MenuItem value={2}>Series A</MenuItem>
        <MenuItem value={3}>Series B</MenuItem>
        <MenuItem value={4}>Series C</MenuItem>
        <MenuItem value={5}>Series D or later</MenuItem>
      </Select>
      ...

    <TextField
      className={classes.textField}
      helperText = "Most Recent Funding Round"
      margin = "normal"
      value = {this.state.startupRecentFunding}
      onChange = {this.handleChange}
      variant = "outlined"
      label = "Amount"
      name = "startupRecentFunding"
      InputProps={{
        startAdornment: <InputAdornment position="start">$M</InputAdornment>,
      }}
    />
...

{this.state.recentError}
*/
